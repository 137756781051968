import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { BundleLineItem } from '../../util/bonsaiClient';

export const selectSelectedRecipeId = (state: RootState) =>
  state.recipes.selectedRecipeId;

export const selectBundleRecipes = (state: RootState) =>
  state.recipes.bundleRecipes;

export const selectBundleLineItems = (state: RootState) =>
  state.recipes.bundleLineItems;

export const selectSelectedRecipe = createSelector(
  selectSelectedRecipeId,
  selectBundleRecipes,
  (recipeId, bundleRecipes) => bundleRecipes.find(r => r.id === recipeId),
);

export const selectSiteBasket = (state: RootState) => state.recipes.siteBasket;

export const selectBasketfulJsIsReady = (state: RootState) =>
  state.recipes.basketfulJsIsReady;

export const selectRecipeIsBusy = (state: RootState) =>
  state.recipes.recipeIsBusy;

export const selectSiteBasketCount = (state: RootState) =>
  state.recipes.siteBasket.count;

export const selectBundleLineItemsFlat = createSelector(
  selectBundleLineItems,
  bundleLineItems => {
    const products: BundleLineItem[] = [];
    bundleLineItems.forEach(b => {
      b.lineItems.forEach(l => {
        products.push({
          upc: l.upc,
          id: l.id,
          imageUrl: l.imageUrl,
          name: l.name,
        });
      });
    });
    return products;
  },
);

export const selectBrandDetails = (state: RootState) =>
  state.recipes.brandDetails;

export const selectBrandFetched = (state: RootState) =>
  state.recipes.brandFetched;
