import React, { FC, memo, useState } from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import { useSelector } from 'react-redux';
import Image from 'next/legacy/image';
import { useMediaQuery } from 'react-responsive';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import useStyles from '../../styles/recipes/recipeStyles';
import PageHead from '../../components/Head/Head';
import { placeholderUrl } from '../../util/functions/ui.functions';
import { BundleRecipe } from '../../util/bonsaiClient';
import { MISSING_IMAGE_URL } from '../../util/constants';
import { ListItem } from '@mui/material';
import { List } from '@mui/material';

import {
  selectSelectedRecipe,
  selectRecipeIsBusy,
} from '../../redux/selectors/recipeSelectors';

interface RecipeViewProps {
  customTheme?: any;
  isInBasket: boolean;
  addRecipe: (recipe: BundleRecipe) => void;
  removeRecipe: (recipe: BundleRecipe) => void;
}

const RecipeView: FC<RecipeViewProps> = ({
  isInBasket,
  customTheme,
  addRecipe,
  removeRecipe,
}) => {
  const classes = useStyles();
  const useCustomStyle = makeStyles({
    brandAddButton: {
      textTransform: 'none',
      width: '90%',
      backgroundColor: customTheme?.palette.primary.main,
      color: '#FFFFFF',
      height: '36px',
      fontSize: '16px',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: customTheme?.palette.primary.main,
        opacity: 0.75,
        boxShadow: 'none',
      },
      '@media (min-width: 768px)': {
        height: '44px',
        marginTop: '8px',
        width: '100%',
      },
    },
  });
  const customClasses = useCustomStyle();
  const isDesktop = useMediaQuery({
    query: '(min-width: 768px)',
  });
  const [isViewing, setIsViewing] = useState(false);
  const recipe = useSelector(selectSelectedRecipe);
  // eslint-disable-next-line max-len
  const recipeIsBusy = useSelector(selectRecipeIsBusy);

  const toggleView = () => {
    setIsViewing(!isViewing);
  };

  const handleRemoveRecipe = async (clickedRecipe: BundleRecipe) => {
    await removeRecipe(clickedRecipe);
  };

  const handleAddRecipe = async (clickedRecipe: BundleRecipe) => {
    await addRecipe(clickedRecipe);
  };

  const aboutPreview = `${recipe && recipe?.description?.slice(0, 180)}...`;

  const Accordions = () => (
    <Grid container spacing={isDesktop ? 12 : 2}>
      <Grid item xs={isDesktop ? 6 : 12}>
        {recipe?.ingredients && recipe?.ingredients?.length > 0 && (
          <Accordion
            sx={{
              boxShadow: 'none',
            }}
            square
            defaultExpanded
          >
            <AccordionSummary
              classes={{ root: classes.summary }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="ingredients-content"
              id="ingredients-accordion"
            >
              Ingredients
              <span className={classes.length}>
                {recipe?.ingredients.length} Items
              </span>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.details }}>
              <List className={classes.eachInstruction}>
                {recipe?.ingredients?.map(item => (
                  <ListItem key={item.id}>{item.ingredient}</ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        )}
      </Grid>
      <Grid item xs={isDesktop ? 6 : 12}>
        {recipe?.directions && recipe?.directions?.length > 0 && (
          <Accordion
            sx={{
              boxShadow: 'none',
            }}
            square
            defaultExpanded
          >
            <AccordionSummary
              classes={{ root: classes.summary }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="instructions-content"
              id="instructions-accordion"
            >
              Instructions
              <span className={classes.length}>
                {recipe?.directions?.length} Steps
              </span>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.details }}>
              <Grid>
                <List
                  sx={{ listStyle: 'decimal', pl: 4 }}
                  className={classes.eachInstruction}
                >
                  {recipe?.directions?.map((direction, index) => (
                    <ListItem key={index} sx={{ display: 'list-item' }}>
                      {direction.text}
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
      </Grid>
    </Grid>
  );

  const recipeAboutBuilder = () => {
    if (recipe?.description) {
      if (recipe.description.length > 180) {
        return (
          <div>
            {isViewing ? (
              <div className={classes.about}>
                {recipe.description}
                <span
                  onClick={toggleView}
                  onKeyDown={toggleView}
                  role="button"
                  tabIndex={0}
                  className={classes.viewMore}
                >
                  View Less
                </span>
              </div>
            ) : (
              <div className={classes.about}>
                {aboutPreview}
                <span
                  onClick={toggleView}
                  onKeyDown={toggleView}
                  role="button"
                  tabIndex={0}
                  className={classes.viewMore}
                >
                  View More
                </span>
              </div>
            )}
          </div>
        );
      }
      return <div className={classes.about}>{recipe.description}</div>;
    }
    return <br />;
  };

  return (
    <>
      {recipe && (
        <div data-testid="recipeInfo" className={classes.recView}>
          <PageHead title={recipe.title} />

          <>
            {isDesktop && (
              <div className={classes.desktopContainer}>
                <Grid sx={{ mt: 0 }} spacing={5} container>
                  <Grid item xs={12}>
                    <div className={classes.recName}>{recipe.title}</div>
                    {recipeAboutBuilder()}
                    <div className={classes.recImageContainer}>
                      <Image
                        placeholder="blur"
                        blurDataURL={`data:image/svg+xml;base64,${placeholderUrl}`}
                        src={recipe.imageUrl || MISSING_IMAGE_URL}
                        alt={recipe.heroAltText}
                        layout="fill"
                        objectFit="cover"
                      />
                    </div>
                    {!isInBasket && (
                      <Button
                        onClick={() => handleAddRecipe(recipe)}
                        variant="contained"
                        className={customClasses.brandAddButton}
                        disabled={recipeIsBusy}
                        startIcon={<AddShoppingCartIcon />}
                        aria-label={`Add ${recipe.title} recipe to cart`}
                      >
                        Add to Cart
                      </Button>
                    )}
                    {isInBasket && (
                      <Button
                        onClick={() => handleRemoveRecipe(recipe)}
                        variant="contained"
                        className={customClasses.brandAddButton}
                        disabled={recipeIsBusy}
                        startIcon={<AddShoppingCartIcon />}
                        aria-label={`Remove ${recipe.title} recipe to cart`}
                      >
                        Remove from cart
                      </Button>
                    )}
                    <Accordions />
                  </Grid>
                </Grid>
              </div>
            )}
            {!isDesktop && (
              <>
                <div className={classes.recImageContainer}>
                  <Image
                    placeholder="blur"
                    blurDataURL={`data:image/svg+xml;base64,${placeholderUrl}`}
                    src={recipe.imageUrl}
                    alt={recipe.heroAltText}
                    layout="fill"
                    className={classes.recImage}
                  />
                  {recipe.source && (
                    <div className={classes.source}>
                      Recipe by {recipe.source} &reg;
                    </div>
                  )}
                </div>
                <div className={classes.imageCover} />
                <div className={classes.content}>
                  <div className={classes.recName}>{recipe.title}</div>
                  {recipeAboutBuilder()}
                  <Accordions />
                </div>
                {isInBasket && (
                  <div className={classes.brandAddButtonContainer}>
                    <Button
                      onClick={() => handleRemoveRecipe(recipe)}
                      variant="contained"
                      color="primary"
                      disabled={recipeIsBusy}
                      className={customClasses.brandAddButton}
                      startIcon={<RemoveShoppingCartIcon />}
                      aria-label={`Remove ${recipe.title} recipe to cart`}
                    >
                      Remove from cart
                    </Button>
                  </div>
                )}
                {!isInBasket && (
                  <div className={classes.brandAddButtonContainer}>
                    <Button
                      onClick={() => handleAddRecipe(recipe)}
                      variant="contained"
                      color="primary"
                      disabled={recipeIsBusy}
                      className={customClasses.brandAddButton}
                      startIcon={<AddShoppingCartIcon />}
                      aria-label={`Add ${recipe.title} recipe to cart`}
                    >
                      Add to Cart
                    </Button>
                  </div>
                )}
              </>
            )}
          </>
        </div>
      )}
    </>
  );
};

export default memo(RecipeView);
