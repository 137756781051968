import { makeStyles } from '@mui/styles';

const RecipeStyles = makeStyles(theme => ({
  wholeContainer: {
    '@media (min-width: 1025px)': {
      '&&': {
        width: '80%',
      },
      marginLeft: '10%',
    },
    '@media (min-width: 1200px)': {
      '&&': {
        width: '70%',
      },
      marginLeft: '15%',
    },
    '@media (min-width: 1920px)': {
      marginLeft: 'calc(50% - 672px)',
    },
    maxWidth: '1344px',
  },
  recView: {
    backgroundColor: theme.palette.background.default,
  },
  recImageContainer: {
    position: 'relative',
    height: '200px',
    width: '100vw',
    maxHeight: '200px',
    maxWidth: '100vw',
    '@media (min-width: 768px)': {
      minWidth: '100%',
      maxWidth: '100%',
      minHeight: '360px',
      maxHeight: '360px',
      marginBottom: '16px',
      marginTop: '8px',
    },
  },
  recImage: {
    objectFit: 'contain',
  },
  imageCover: {
    position: 'absolute',
    top: 0,
    height: '200px',
    width: '100%',
    backgroundImage:
      'linear-gradient(to bottom, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.8) 100%)',
  },
  content: {
    padding: theme.spacing(2, 2, 11),
    '@media (min-width: 768px)': {
      padding: theme.spacing(0, 4, 8),
    },
  },
  recName: {
    marginTop: theme.spacing(1),
    fontSize: '22px',
    fontWeight: 600,
    '@media (min-width: 1025px)': {
      marginTop: '0px',
      fontSize: '28px',
      marginBottom: '-8px',
    },
  },
  brandRecName: {
    fontSize: '18px',
    fontWeight: 600,
    width: '100vw',
    marginLeft: '-16px',
    padding: theme.spacing(1, 1, 0, 1),
    '@media (min-width: 768px)': {
      fontSize: '22px',
    },
  },
  rating: {
    marginTop: theme.spacing(1),
  },
  about: {
    color: 'dimgrey',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    '@media (min-width: 768px)': {
      fontSize: '16px',
    },
  },
  snapshot: {
    color: '#313131',
    fontSize: '14px',
    alignItems: 'center',
    marginTop: theme.spacing(0.5),
    '@media (min-width: 768px)': {
      textAlign: 'center',
      fontSize: '16px',
    },
  },
  snaplabel: {
    marginTop: theme.spacing(2),
    fontSize: '14px',
    color: '#707070',
    '@media (min-width: 768px)': {
      marginTop: theme.spacing(1),
      textAlign: 'center',
      fontSize: '16px',
    },
  },
  noData: {
    color: 'silver',
  },
  backButton: {
    position: 'fixed',
    top: 10,
    zIndex: 1000,
    color: theme.palette.common.black,
  },
  quantSelector: {
    position: 'absolute',
    right: 16,
    marginTop: theme.spacing(1),
  },
  subText: {
    fontSize: '11px',
    fontStyle: 'italic',
    marginTop: -theme.spacing(2.5),
    fontWeight: 300,
  },
  ingredType: {},
  viewInstructions: {
    marginTop: theme.spacing(1),
    textDecoration: 'underline',
    color: theme.palette.primary.main,
  },
  view: {
    textAlign: 'right',
    marginRight: theme.spacing(1),
  },
  viewMore: {
    marginLeft: theme.spacing(1),
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  source: {
    fontSize: '10px',
    textDecoration: 'underline',
    color: theme.palette.common.white,
    zIndex: 1,
    position: 'absolute',
    bottom: 8,
    left: 8,
  },
  clickToView: {
    cursor: 'pointer',
  },
  addButtonContainer: {
    position: 'fixed',
    bottom: 70,
    right: 16,
    width: '62px',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 20,
    '@media (min-width: 768px)': {
      position: 'absolute',
      bottom: 25,
      right: 30,
      width: '80px',
    },
  },
  brandAddButtonContainer: {
    position: 'fixed',
    bottom: 8,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    '@media (min-width: 768px)': {
      width: '80%',
      maxWidth: '80%',
    },
    '@media (min-width: 1200px)': {
      width: '70%',
      maxWidth: '70%',
    },
  },
  addButton: {
    boxShadow: '0px 0px 6px #00000050',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 400,
    height: '62px',
    width: '62px',
    borderRadius: '50%',
    '@media (min-width: 768px)': {
      width: '80px',
      height: '80px',
      zIndex: 500,
    },
  },
  units: {
    color: 'grey',
    fontSize: '12px',
    fontWeight: 400,
    paddingTop: '4px',
    paddingBottom: '4px',
  },
  servingSize: {
    marginBottom: '0px',
  },
  arrow: {
    marginBottom: '2px',
  },
  yield: {
    height: '19px',
  },
  eachInstruction: {
    fontSize: '14px',
    '@media (min-width: 768px)': {
      fontSize: '16px',
    },
  },
  summary: {
    padding: theme.spacing(0.5, 0),
    maxHeight: theme.spacing(7),
    fontSize: '18px',
    fontWeight: 700,
  },
  category: {
    color: '#969595',
    fontSize: '14px',
    '@media (min-width: 768px)': {
      marginTop: '8px',
    },
    '@media (min-width: 1025px)': {
      fontSize: '16px',
    },
  },
  details: {
    margin: 0,
    padding: 0,
  },
  ingredRow: {
    width: '100%',
  },
  length: {
    lineHeight: '24px',
    textAlign: 'right',
    color: '#969595',
    width: '100%',
    fontSize: '14px',
    fontWeight: 500,
  },
  logoContainer: {
    position: 'relative',
    height: '35px',
    width: '100px',
    maxHeight: '35px',
    maxWidth: '100px',
    marginBottom: '8px',
    '@media (min-width: 768px)': {
      height: '50px',
      width: '135px',
      maxHeight: '50px',
      maxWidth: '135px',
      marginTop: '4px',
    },
  },
  logo: {
    position: 'absolute',
    maxHeight: '100%',
    maxWidth: '100%',
  },
  desktopContainer: {
    '@media (min-width: 768px)': {
      width: '90%',
      marginLeft: '5%',
    },
    '@media (min-width: 1025px)': {
      width: '80%',
      marginLeft: '10%',
    },
  },
}));

export default RecipeStyles;
